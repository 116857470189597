<template>
  <div class="Userhl_jianli_q Userhl_rightbox">
    <ul class="Userhl_jianli_qul">
      <li
        class="Userhl_jianli_qli"
        v-for="(item, index) in jianliall"
        :key="index"
        v-show="item.realname!=null"
      >
        <div class="Userhl_jianli_qlil">
          <img :src="item.userpic" alt="" />
        </div>
        <div class="Userhl_jianli_qlic">
          <p>{{ item.realname }}</p>
          <p>
            <span>上海</span>
            |
            <span>{{ item.major }}</span>
          </p>
          <p>
            {{ item.school }}-
            <span v-if="item.education == 3">大专</span>
            <span v-if="item.education == 4">本科</span>
            <span v-if="item.education == 5">硕士</span>
            <span v-if="item.education == 6">MBA</span>
            <span v-if="item.education == 7">EMBA</span>
            <span v-if="item.education == 8">博士</span>
            <span v-if="item.education == 9">其他</span>
            -{{ item.major }}
          </p>
          <p>工作时间：{{ item.uhworkstime }}</p>
        </div>
        <div class="Userhl_jianli_qlir">
          <div class="Userhl_jianli_qlirimgbox">
            <div class="Userhl_jianli_qlirimg">
              <icon-svg icon-class="iconpdf" />
              <div><a :href="item.worksadd" download="简历">下载简历</a></div>
            </div>
            <div class="Userhl_jianli_qlirimg">
              <icon-svg icon-class="iconfujian" />
              <div>
                <a :href="item.worksadd" download="简历">下载附件</a>
              </div>
            </div>
            <!-- <div class="Userhl_jianli_qlirimg">
              <icon-svg icon-class="icontousu" />
              <div @click="dialogVisible = true">投诉</div>
            </div> -->
          </div>
        </div>
      </li>
    </ul>
    <el-dialog
      title="投诉内容填写"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      textarea: "",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      userinfo: {},
      jianliall: [],
    };
  },
  methods: {
    jlaxios() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserPayResume",
          method: "post",
          data: {
            page: "1",
            limit: "10",
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.jianliall = res.data.result.list;
          } else {
            that.$message(res.data.messages);
          }
        });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.jlaxios();
  },
};
</script>
